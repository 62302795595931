import { useEffect } from 'react'
import { Canvas } from '@react-three/fiber'
import { RoundedBox, ScrollControls, Scroll, Environment,Sparkles,Backdrop,Float, Ring } from '@react-three/drei'
import { Robot } from './components/Robot'
import { Scene } from './components/Scene'
import baffle from 'baffle'


function App() {

  useEffect(()=> {
    const target = baffle('.title')
    target.set({
      characters: '░G░e░r░a░n░',
      speed: 100
    })
    target.start()
    target.reveal(1000,1000)
  })

  return (
    <>
      <Canvas shadows>
        <color attach="background" args={['#333333']} />
        <ambientLight intensity={0.2} />
        <spotLight position={[0, 25, 0]} angle={1.3} penumbra={1} castShadow intensity={2} shadow-bias={-0.0001} />
        
        <Environment preset='warehouse'/>

        <ScrollControls pages={6} damping={0.1}>

          {/* <Robot scale={0.8}/> */}
          <Scene scale={[2, 2, 2]} />

          <Sparkles size={2} color={"#f00"} scale={[10,10,10]}></Sparkles>

          {/* <Sparkles size={2} color={"#fff"} scale={[10,10,10]}></Sparkles> */}
          <Backdrop
            receiveShadow
            floor={20.5} // Stretches the floor segment, 0.25 by default
            segments={100} // Mesh-resolution, 20 by default
            scale={[50,30,10]}
            position={[4,-10,0]}
          >
            <meshStandardMaterial color="#000000" />
            {/* <meshStandardMaterial color="#0a1a1f" /> */}
          </Backdrop>  

          <Float
            speed={4} // Animation speed, defaults to 1
            rotationIntensity={0.5} // XYZ rotation intensity, defaults to 1
            floatIntensity={1} // Up/down float intensity, works like a multiplier with floatingRange,defaults to 1
            floatingRange={[1, 1]} // Range of y-axis values the object will float within, defaults to [-0.1,0.1]
            >
            <Ring scale={3.5} position-z={-2.5} position-y={-1} args={[0, 0.95, 60]} receiveShadow>
              {/* <meshStandardMaterial color="#2a3a3f" /> */}
              <meshStandardMaterial color="#111" />
            </Ring>
          </Float>              

          <Scroll>
                  
          </Scroll>
          <Scroll html className="w-full">
            {/* <h1 className="title" style={{ color: '#cdcbca',position: 'absolute', top: `85vh`,left: '50%', fontSize: '13em', transform: `translate(-50%,-50%)` }}>
              Герань-2 (Geran-2)
            </h1> */}

            <h1 className="title absolute top-[85vh] left-[50%] -translate-x-1/2 text-[#cdcbca] text-7xl">
              Герань-2
            </h1>
            
            <div className="w-full mx-12" style={{ position: 'absolute', top: `132vh`}}>
              <h2>Suicide Drone</h2>
              <p className="mt-10 max-w-[600px]">
                The Geran-2 aircraft has a cropped delta-wing shape, with a central fuselage blending into the wings and stabilizing rudders at the tips. The nose section contains a warhead estimated to weigh 30–50 kilograms. The engine sits in the rear of the fuselage and drives a two-bladed propeller in a "pusher" arrangement.
              </p>
              
              {/* <button className="mt-10">Read more</button> */}
            </div>

            <div className="w-full mx-12" style={{ position: 'absolute', top: `230vh`}}>
              <div className="col" style={{ position: 'absolute', right: `40px`, width: "540px"}}>
                <h2 style={{ maxWidth: "440px" }}>Specifications</h2>
                <p className="mt-10 max-w-[600px]"><span className="font-bold">Mass</span> 200kg</p>
                <p className="mt-4 max-w-[600px]">Length 3.5m</p>
                <p className="mt-4 max-w-[600px]">Warhead weight 30-50 kilograms</p>
                <p className="mt-4 max-w-[600px]">Engine MD-550 piston engine(two stroke)</p>
                <p className="mt-4 max-w-[600px]">Wingspan 2.5m</p>
                <p className="mt-4 max-w-[600px]">Operational Range estimates between 970-2500km</p>
                <p className="mt-4 max-w-[600px]">Maximum speed 185km/h</p>
                {/* <p style={{ maxWidth: '440px' }}>Featuring a sleek, metallic design inspired by advanced technology, this aftershave bottle is as stylish as it is functional. But it's not just a pretty face - inside, you'll find a nourishing and protective aftershave formula that will leave your skin feeling refreshed and hydrated.</p>                 */}
                {/* <button className="mt-10">Read more</button> */}
              </div>
            </div>
            
            <h2 style={{ position: 'absolute', top: '350vh', left: '50%', transform: `translate(-50%,-50%)` }}>ебать нацистов</h2>              
            
            <button style={{ position: 'absolute', top: `590vh`,left: '50%', transform: `translate(-50%,-50%)` }}>Buy now</button>
          </Scroll>
        </ScrollControls>
      </Canvas>
    </>
  );
}

export default App
