import { Vector3 } from 'three'
import React, { useRef, useState, useLayoutEffect  } from 'react'
import { useFrame, useThree } from '@react-three/fiber'
import { useGLTF, useScroll, SpotLight, useDepthBuffer } from '@react-three/drei'
import gsap from 'gsap'

function MovingSpot({ vec = new Vector3(), ...props }) {
    const light = useRef()
    const viewport = useThree((state) => state.viewport)
    useFrame((state) => {
      light.current.target.position.lerp(vec.set((state.mouse.x * viewport.width) / 2, (state.mouse.y * viewport.height) / 2, 0), 0.1)
      light.current.target.updateMatrixWorld()
    })
    return <SpotLight castShadow ref={light} penumbra={1} distance={6} angle={0.35} attenuation={5} anglePower={4} intensity={2} {...props} />
}  

export function Scene(props) {
    
    const ref = useRef()
    const propellerRef = useRef()

    const { nodes, materials } = useGLTF('/geranium.glb')
    const scroll = useScroll()
    const tl = useRef()

    const depthBuffer = useDepthBuffer({ frames: 1 })

    useFrame((state, delta) => (propellerRef.current.rotation.y += delta * 50))

    useFrame((state, delta)=>{
        tl.current.seek(scroll.offset * tl.current.duration())
    })

    useLayoutEffect(()=> {
        tl.current = gsap.timeline({defaults: {duration: 2, ease: 'power1.inOut'}})

        tl.current
        .to(ref.current.rotation, {y: 0}, 2)
        .to(ref.current.position, {x: 1}, 2)
        .to(ref.current.position, {z: 1}, 2)

        .to(ref.current.rotation, {y: Math.PI / 2}, 6)   
        .to(ref.current.position, {x: -1}, 6)

        .to(ref.current.rotation, {y: Math.PI / 2}, 11)
        .to(ref.current.rotation, {x: 3}, 11)
        .to(ref.current.position, {x: 0}, 11)

        .to(ref.current.rotation, {y: 0}, 13)
        .to(ref.current.rotation, {x: -1}, 13)    
        .to(ref.current.position, {x: 0}, 13)
        .to(ref.current.position, {z: -1}, 13)

        .to(ref.current.rotation, {y: 0}, 16)   
        .to(ref.current.rotation, {x: 0}, 16) 
        .to(ref.current.position, {x: 0}, 16)    

        .to(ref.current.rotation, {y: 0}, 20)   
        .to(ref.current.rotation, {x: Math.PI / 2}, 20) 
        .to(ref.current.position, {x: 0}, 20) 

    },[])


    
    return (
        <>
            <MovingSpot depthBuffer={depthBuffer} color="#0c8cbf" position={[3, 3, 2]} />
            <MovingSpot depthBuffer={depthBuffer} color="#b00c3f" position={[-3, 3, 2]} />
            <group ref={ref} rotation={[Math.PI / 2, Math.PI, 0]} {...props} dispose={null}>
                <mesh
                    ref={propellerRef}
                    geometry={nodes.propeller.geometry}
                    material={materials.propeller}
                    position={[0, 0.01, -0.9]}
                    rotation={[-Math.PI / 2, 0, 0]}
                    />
                <mesh
                    geometry={nodes.geran.geometry}
                    material={materials.propeller}
                    rotation={[-Math.PI / 2, 0, 0]} />
            </group>
        </>
    )
}

useGLTF.preload('/geranium.glb')
